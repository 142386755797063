%id {
  width: rem(60);
}
%name {
  width: rem(180);
}
%email {
  width: rem(240);
}
%phone-number {
  width: rem(120);
}
%fo-buildings {
  width: rem(120);
}
%hired-cases {
  width: rem(100);
}
%sanisaver-income {
  width: rem(136);
}
