table,
.table {
  border-collapse: collapse;

  thead,
  .thead {
    tr,
    .tr {
      background-color: $selago;
      border-radius: rem(8) rem(8) 0 0;
    }
  }

  &,
  tbody,
  .tbody {
    tr,
    .tr {
      &:nth-child(even) {
        background-color: rgba($selago, 0.5);
        border-radius: rem(6);
      }
    }
  }
}

%table {
  width: 100%;
  display: block;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .table-head,
  .table-body {
    display: block;
  }
}

%table-row {
  width: 100%;
  display: inline-table;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;

  td {
    padding: rem(25) rem(8);

    &:first-child {
      padding-left: rem(30);
    }

    &:last-child {
      padding-right: rem(30);
    }

    > * {
      font-family: $fontFamily;
      font-weight: 400;
    }
  }
}

%table-head-row {
  @extend %table-row;

  td {
    > * {
      font-size: rem(12);
      line-height: rem(14);
      color: $stormGray;
      text-transform: uppercase;
    }
  }
}

%table-body-row {
  @extend %table-row;

  td {
    > * {
      display: inline-block;
      font-size: rem(14);
      line-height: rem(16);
    }
  }
}
