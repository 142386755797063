@import '../../../styles/main';

@mixin quiz-modal {
  background-color: #6b738a;
  opacity: 0.8;
  width: 200%;
  height: 100%;
  display: flex;
  position: fixed;
  justify-items: center;
  align-items: center;
}

@mixin quiz-modal-container {
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100vw;
  .quiz-modal-content {
    overflow-y: auto;
    width: rem(887);
    .quiz-header {
      .close-button {
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        padding-top: rem(15);
        padding-right: rem(15);
        width: min-content;
        float: right;
        div {
          width: rem(17);
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        padding: rem(70);
        .progress {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .links {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          padding: rem(15);
        }
      }
      .circle-progress {
        width: 15%;
      }
      background-color: $white;
      border-radius: rem(16);
      margin-top: rem(30);
      height: rem(217);
      label {
        font-size: rem(12);
        font-weight: 400;
        color: #6b738a;
        text-transform: uppercase;
      }
      input {
        font-weight: 500;
        font-size: rem(35);
        border-bottom: 1px solid #caccd4;
        width: 80%;
      }
    }
    .quiz-header:hover > .content > input {
      animation: pulse 1s;
    }
    .quiz-header:hover > .content > label {
      animation: increaseFont 2s forwards;
    }
    .quiz-header:hover > .close-button > div > svg {
      animation: animateCloseButton 1.5s forwards;
    }
    .quiz-body {
      overflow: auto;
      padding: rem(20) rem(15) rem(40) rem(20);
      border-radius: rem(16);
      margin-top: rem(20);
      background-color: $white;
      max-height: 56vh;
      .add-button {
        box-shadow: 0px 5px 28px rgba(78, 104, 238, 0.09);
        border-radius: 10px;
        height: rem(68);
        width: 100%;
        margin-top: rem(20);
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: rem(20);
      }
      .cancel-button {
        font-weight: 400;
        width: rem(146);
        font-size: rem(14);
        color: rgba(107, 115, 138, 0.7);
        background-color: transparent;
        height: rem(46);
        float: right;
      }
      .save-button {
        @extend .cancel-button;
        background-color: $royalBlue;
        color: $white;
        font-weight: 500;
        font-size: rem(15);
        box-shadow: 0px 4px 20px rgba(78, 104, 238, 0.3);
        border-radius: 6px;
      }
    }
    .quiz-body::-webkit-scrollbar {
      width: rem(2);
    }
    .quiz-body::-webkit-scrollbar-thumb {
      background-color: $cornBlue;
      outline: 1px solid slategrey;
    }
  }
  @keyframes increaseFont {
    to {
      font-size: rem(15);
    }
  }

  @keyframes animateCloseButton {
    to {
      transform: rotate3d(-1, 2, 2, 360deg) scale(1.5);
      transform-origin: 50% 50%;
    }
  }
}
