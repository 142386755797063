@import '../../../styles/main';

@import './Notes/styles';

@mixin activity {
  grid-area: activity;
  padding: rem(61) 0 rem(35) rem(53);
  min-height: calc(100vh - #{$headerHeight});

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  background-color: $zircon;

  & > * {
    margin-right: rem(28);
  }
  .activity-container {
    .title {
      margin-bottom: rem(28);
    }
    .activities-container {
      position: relative;
      margin-right: 0;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          transparent,
          rgba($zircon, 0) 60%,
          #fbfcff 100%
        );
        pointer-events: none;
      }
      .activities {
        padding: rem(28) rem(28) rem(28) 0;
        margin-bottom: rem(28);
        display: flex;
        flex-direction: column;
        align-items: stretch;

        max-height: rem(493);
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        .activity-item {
          @extend %row;
          margin-bottom: rem(18);
          .activity-date {
            width: rem(53);
            margin-right: rem(47);
            @include ellipsis(1);
            .month {
              color: $stormGray;
            }
            .day {
              font-weight: 500;
            }
          }
          .activity-text {
            max-width: calc(100% - #{rem(100)});
            flex-grow: 1;
            padding: rem(19) rem(28);
            background-color: $white;
            box-shadow: 0 rem(1) rem(28) rgba($prussianBlue, 0.05);
            border-radius: rem(13);
            b {
              color: $royalBlue;
            }
          }
          .no-activity {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  .notes {
    @include notes;
  }
}
