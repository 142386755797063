@import 'variables';
@import 'functions';

%center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

%center-inline {
  display: flex;
  justify-content: center;
  align-items: center;
}

%fullwidth {
  @include width-padding($maxPageWidth);
  @include media-xxl {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

%blue-button {
  @extend %center-container;
  min-width: rem(120);
  min-height: rem(46);
  padding: rem(10) rem(16);
  border-radius: rem(6);
  background-color: $royalBlue;
  box-shadow: 0 rem(4) rem(20) rgba($royalBlue, 0.3);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  font-family: $fontFamily;
  font-weight: 500;
  font-size: rem(15);
  line-height: rem(25);
  text-align: center;
  color: $white;

  &:hover:not(:disabled):not(.disabled) {
    color: $white;
    background-color: $cornBlue;
    box-shadow: 0 rem(4) rem(20) rgba($royalBlue, 0.49);
  }
  &:active:not(:disabled):not(.disabled) {
    color: $white;
    background-color: $persianBlue;
    box-shadow: 0 rem(4) rem(20) rgba($royalBlue, 0.3);
  }

  &:disabled,
  &.disabled {
    color: $white;
    background-color: rgba($royalBlue, 0.5);
    cursor: not-allowed;
  }
}

%blue-color {
  transition: color 0.3s;
  &,
  :visited {
    color: $royalBlue;
  }
  &:hover {
    color: rgba($royalBlue, 0.8);
  }
  &:active {
    color: $persianBlue;
  }
}

%row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

%column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition: $property;
}
@mixin transform-origin($property) {
  -webkit-transform-origin: $property;
  -moz-transform-origin: $property;
  -o-transform-origin: $property;
  transform-origin: $property;
}

@mixin no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
