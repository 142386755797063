%ticket-number {
  width: rem(100);
}
%infraction-code {
  width: rem(60);
}
%address {
  width: rem(232);
}
%respondent {
  width: rem(180);
}
%hearing-date {
  width: rem(120);
}
%hearing-status {
  width: rem(118);
}
%case-status {
  width: rem(120);
  overflow-wrap: break-word;
  display: inline-block;
  text-align: center;
}
%balance {
  width: rem(100);
}
%status {
  width: rem(60);
}
