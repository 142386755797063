@import '../../../styles/main';

@mixin table-section {
  max-width: 100%;
  .heading {
    @extend %row;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    padding-top: rem(35);
  }
  .search-wrapper {
    width: 100%;
    @extend %row;

    .search-icon {
      width: rem(9);
      height: rem(9);
      margin-right: rem(6);
      &,
      * {
        fill: $royalBlue;
      }
    }
    .search-container {
      align-self: flex-start;
      .search {
        border: none;
        box-shadow: none;
        padding-left: 0;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .table-container {
    width: 100%;
    .table {
      @extend %table;
      margin-top: rem(22);
      margin-bottom: rem(25);
    }
  }
}
