button {
  font-family: $fontFamily;
  font-size: rem(14);
  line-height: rem(16);
  background-color: transparent;
}

.primary-button {
  @extend %blue-button;
}

.small-button {
  font-family: $fontFamily;
  font-size: rem(14);
  line-height: rem(16);
  @extend %blue-color;
}
