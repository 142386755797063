@import '../../styles/main';

.violations {
  .page-content {
    padding-bottom: rem(70);
    .heading {
      @extend %row;
      justify-content: space-between;
      flex-wrap: wrap;

      width: 100%;
      padding-top: rem(35);

      .navigation {
        @extend %row;
        justify-content: space-between;
        flex-wrap: wrap;
        .links {
          @extend %row;
          margin-right: rem(63);

          .link-item {
            margin: rem(8);
            .link {
              position: relative;
              display: inline-block;
              padding: rem(6);

              font-family: $fontFamily;
              font-size: rem(14);
              line-height: rem(16);
              color: $stormGray;

              text-align: center;

              &:hover {
                color: rgba($codGray, 0.7);
              }

              &.link-active,
              &:active {
                color: $codGray;
              }

              &.link-active {
                &::after {
                  position: absolute;
                  content: '';
                  display: inline-block;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);

                  width: calc(100% - #{rem(12)});
                  height: 1px;
                  background-color: $royalBlue;
                }
              }
            }

            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .filters-active {
        color: $stormGray;
        padding: rem(15px);
      }
    }
    .search-wrapper {
      width: 100%;
      @extend %row;

      .search-icon {
        width: rem(9);
        height: rem(9);
        margin-right: rem(6);
        &,
        * {
          fill: $royalBlue;
        }
      }
      .search-container {
        align-self: flex-start;
        .search {
          border: none;
          box-shadow: none;
          padding-left: 0;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .navigation-wrapper {
    display: flex;
    flex-wrap: wrap;

    h1 {
      margin-right: 1.75rem;
    }
  }

  .filters-wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
}

.select {
  display: flex;
  flex-direction: row;
  align-items: center;

  > label {
    margin-bottom: 0;
    color: $stormGray;
    font-size: 0.875rem;
    margin-right: 4px;
    font-weight: 400;
  }

  :global {
    .react-select__menu {
      font-size: 0.875rem;
      right: 0;
      top: calc(100% + 0.5rem);
      padding: 1rem;
      width: max-content;
      border-radius: 0.375rem;

      background: #fff;

      /* Shadow */
      box-shadow: 0px 1px 28px 0px rgba(0, 31, 79, 0.05);
    }

    .react-select__option {
      margin-bottom: 0.75rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
