@import 'reset';
@import 'fonts';

@import 'main';

html,
body,
.page {
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  font-size: $fontSize;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
}

html {
  overflow-y: scroll;
}

#modal-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

@include media-lg {
  html,
  body {
    font-size: calc(#{$fontSize} - 1px);
  }
}

@include media-md {
  html,
  body {
    font-size: calc(#{$fontSize} - 2px);
  }
}

@include media-sm {
  html,
  body {
    font-size: calc(#{$fontSize} - 2px);
  }
}

@include media-xs {
}

@include media-xxs {
  html,
  body,
  .page {
    width: 320px !important;
  }
}

form,
.form {
  display: flex;
  flex-direction: column;
}

button {
  background-color: transparent;
  cursor: pointer;
}

@import 'link';
@import 'input';
@import 'text';
@import 'image';
@import 'table';
@import 'button';

.center-container {
  @extend %center-container;
}

.page {
  flex-direction: column;
  align-items: center;

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    flex-grow: 1;

    @extend %fullwidth;
  }
}

.fullwidth {
  @extend %fullwidth;
}

.card {
  display: inline-flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 rem(1) rem(48) rgba($royalBlue, 0.09);
  border-radius: rem(10);
  padding: rem(70) rem(60) rem(88);
  @include media-xs {
    padding: rem(40) rem(35) rem(49);
  }
}

.big-card {
  min-width: css-min(rem(499), 100%);
  min-height: rem(583);
}
