@import '../../../styles/main';

@mixin defences-list {
  width: 100%;
  display: grid;
  grid-gap: rem(32);
  margin-bottom: rem(25);

  //grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
