h1 {
  font-family: $fontFamily;
  font-weight: 500;
  font-size: rem(35);
  line-height: rem(41);
  color: $mineShaft;
}

h2 {
  font-family: $fontFamily;
  font-weight: 500;
  font-size: rem(20);
  line-height: rem(23);
  color: $codGray;
}

h3 {
  font-family: $fontFamily;
  font-size: rem(14);
  line-height: rem(24);
  color: $stormGray;
}

p,
.text {
  font-family: $fontFamily;
  font-size: rem(14);
  line-height: rem(16);
  color: $codGray;
}

sup {
  font-size: 0.4em;

  // line-height: 0;

  position: relative;

  vertical-align: top;
  top: -0.6em;
}

strong {
  font-weight: 700;
}

.small-text {
  font-size: rem(12);
  line-height: rem(14);
}

.asterisk,
.error-text {
  color: $carnation;
}

.asterisk:empty {
  &::before {
    content: '*';
  }
}

.error-placeholder {
  height: rem(16);
}

.additional-text {
  color: $stormGray;
}

.black-text {
  color: $codGray;
}

%plus {
  width: 1em;
  height: 1em;
  position: relative;
  display: inline-block;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: currentColor;
    border-radius: 1px;
  }
  &::before {
    height: 1px;
    width: 100%;
  }
  &::after {
    height: 100%;
    width: 1px;
  }
}

.plus {
  @extend %plus;
}

.uppercase {
  text-transform: uppercase;
}
