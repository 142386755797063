a,
.link,
.secondary-button {
  &,
  :visited {
    font-family: $fontFamily;
    font-size: rem(14);
    line-height: rem(16);
    text-decoration: none;
    color: rgba($stormGray, 0.7);

    cursor: pointer;
    transition: color 0.3s;
  }
  &:hover {
    color: rgba($stormGray, 0.9);
  }
  &:active {
    color: $stormGray;
  }
}

.blue-link {
  @extend %blue-color;
}

.big-blue-link {
  @extend %blue-button;

  &:hover,
  &:active {
    color: $white;
  }
}

.label-link {
  font-family: $fontFamily;
  font-weight: 500;
  font-size: rem(12);
  line-height: rem(14);
  color: $stormGray;
}
