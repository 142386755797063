@import '../../../styles/main';

@mixin defence-head {
  overflow-x: scroll;
  padding-bottom: 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: grab;

  .defence-head-container {
    @extend %column;
    width: max-content;
    align-items: stretch;
    flex-direction: row;
  }

  .defence-subtitle {
    color: $stormGray;
  }
  .defence-head-block {
    display: flex;
    align-self: center;
    flex-direction: column;
  }
  .defence-section-of-law {
    @extend .defence-head-block;
    padding-left: rem(100);
    padding-right: rem(100);
  }

  .defence-offence-block {
    @extend .defence-head-block;
    padding-left: rem(30);
    padding-right: rem(50);
  }
  .gradient {
    @extend .defence-head-block;
    border-radius: 8px 0px 0px 8px;
    width: rem(7);
    height: rem(67);
  }

  .green-gradient {
    @extend .gradient;
    background: linear-gradient(328.2deg, $fern 8.67%, $chinook 94.61%);
  }
  .blue-gradient {
    @extend .gradient;
    background: linear-gradient(
      178.46deg,
      $portageLight -4.88%,
      $royalBlue 115.26%
    );
  }
  .red-gradient {
    @extend .gradient;
    background: linear-gradient(
      (359.13deg, $carnation 33.84%, rgba(253, 87, 87, 0) 153.17%)
    );
  }
}
