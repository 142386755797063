@import '../../../styles/main';

@mixin main-info {
  grid-area: main-info;

  .params-list {
    width: 100%;
    padding: 0 rem(41);
    display: grid;
    margin-bottom: rem(60);

    grid-template-columns: repeat(auto-fill, minmax(rem(200), 1fr));
    gap: rem(29);

    .params-list-item {
      @extend %center-container;
      padding: rem(25) rem(32) rem(29);

      background-color: $selago;
      border-radius: rem(13);
      .params-container {
        .param-title {
          color: $stormGray;
          margin-bottom: rem(8);
        }
        .param-value {
          font-weight: 500;
          overflow-wrap: break-word;
          display: inline-block;
        }
      }
    }
  }
  .params-table {
    width: calc(100% - #{rem(82)});
    margin: 0 rem(41);
  }
}
