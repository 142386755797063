@import '../../../styles/main';

@mixin charts {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr rem(329);
  gap: rem(37);
  width: 100%;
  padding: rem(35) 0 rem(46);

  @include media-lg {
    grid-template-columns: 1fr;
  }
}
