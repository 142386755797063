@import '../../../styles/main';

@mixin question-item {
  display: flex;
  padding-left: rem(50);
  padding-right: rem(30);
  margin-top: rem(20);
  .question-item-number {
    padding-right: rem(20);
    padding-top: rem(15);
  }

  .question-item-block {
    background-color: $white;
    box-shadow: 0px 1px 28px rgba(0, 31, 79, 0.05);
    border-radius: 13px;
    padding: rem(16);
    .header {
      display: flex;
      flex-direction: row;
      svg {
        margin-right: rem(20);
      }
      border-bottom: 1px solid rgba(202, 204, 212, 0.25);
    }
    .answers {
      padding: rem(10);
      .answer-item {
        margin-top: rem(7);
        display: flex;
        flex-direction: row;
        p {
          margin-right: rem(4);
          color: $stormGray;
        }
      }
    }
  }
}
