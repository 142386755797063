@import '../../../styles/main';

@mixin head-info {
  grid-area: head-info;
  @extend %column;
  align-items: stretch;
  .title-container {
    padding: rem(29) 0 rem(16);
    @extend %row;
    .back-button {
      display: inline-flex;
      width: rem(16);
      height: rem(30);
      margin-right: rem(10);
      padding: rem(10) rem(10) rem(10) 0;

      .back-icon {
        height: rem(10);
        width: rem(6);
        transform: rotate(180deg);
        &,
        * {
          fill: $royalBlue;
          stroke: $royalBlue;
        }
      }
    }
    .respondent {
      text-transform: capitalize;
    }
  }
  .contacts-container {
    @extend %row;
    flex-wrap: wrap;
    .contact-link {
      @extend %row;
      margin: 0 rem(18) rem(10) 0;
      color: $codGray;

      &:hover {
        color: $stormGray;
      }

      &:active {
        color: rgba($codGray, 0.9);
      }

      &:last-child {
        margin: 0 0 rem(10) 0;
      }

      &.mail-link {
        .mail-icon {
          margin-right: rem(10);
        }
      }
      &.phone-link {
        .phone-icon {
          margin-right: rem(7);
          &,
          * {
            stroke: $stormGray;
          }
        }
      }
    }
  }
}
