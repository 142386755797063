%building-name {
  width: rem(200);
}
%address {
  width: rem(300);
}
%status {
  width: rem(160);
}
%additional-info {
  width: rem(300);
}
%hired-cases {
  width: rem(80);
}
