@import '../../../../styles/main';

@mixin defences-item {
  width: 100%;
  height: rem(260);

  p {
    color: #6b738a;
  }

  .bold {
    font-weight: 700;
    color: $codGray;
  }

  .bold-active {
    font-weight: 700;
    color: $royalBlue;
  }

  .heading {
    @extend %row;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: rem(26) rem(24) rem(18) rem(32);

    h2 {
      color: $codGray;
      font-size: rem(35);
      line-height: rem(41);
      //padding-right: rem(91);
    }
    .penalty {
      margin-left: auto;
      height: rem(46);
      width: rem(70);
      color: #6b738a;
      font-family: $fontFamily;

      background-color: rgba(242, 153, 74, 0.13);
      border-radius: 8px;
      text-align: right;
      padding-right: rem(7);
      padding-top: rem(8);
      font-size: rem(8);
      line-height: 140%;
      p {
        font-size: rem(16);
        line-height: rem(19);
      }
    }
  }
  .divider {
    background: $royalBlue;
    opacity: 0.1;
    border-radius: 5px;
    width: 100%;
    height: 1px;
  }
  .content {
    padding: rem(26) rem(24) rem(18) rem(32);

    p {
      height: 100%;
      min-height: rem(47);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .management-panel {
      @extend %row;
      width: 100%;
      margin-top: rem(27);
      margin-bottom: rem(30);
      justify-content: space-between;
      .management-panel-info {
        @extend %row;
      }
      .row {
        @extend %row;
        margin-right: rem(15);
        div {
          margin-right: rem(5);
        }

        span {
          margin-right: rem(5);
        }
      }
      .def-info-clickbait {
        background-color: $carnation;
        width: rem(6);
        height: rem(6);
        border-radius: 2px;
      }
    }
    .button {
      color: $royalBlue;
      font-weight: 500;
    }
    .button-clickbait {
      @extend %blue-button;
      width: rem(130);
      height: rem(36);
    }
  }
}
