@mixin form-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  padding: rem(49) rem(51) rem(45) rem(59);
  background-color: $zircon;
  border-radius: rem(16) rem(16) 0 0;

  .tab-nav {
    margin-bottom: rem(42);
    .label {
      @include ellipsis(1);
    }
  }

  .form {
    flex-grow: 1;
    .section {
      margin-bottom: rem(44);

      .section-head {
        display: inline-block;
        margin-bottom: rem(12);
        .section-title {
          display: block;
          font-weight: 500;
          font-size: rem(20);
          line-height: rem(23);
          margin-bottom: rem(30);

          &.section-title-small {
            font-weight: 700;
            font-size: rem(14);
            line-height: rem(22);
          }
        }

        .section-description {
          font-size: rem(14);
          line-height: rem(22);
          color: $stormGray;
          margin-bottom: rem(30);
        }
      }

      .sub-section {
        display: grid;
        row-gap: rem(15);
        padding-bottom: rem(25);
        border-color: $mystic;
        border-style: solid;
        border-bottom-width: 1px;

        &.no-border {
          border-bottom-width: 0;
        }
      }

      .sub-section + .sub-section {
        padding-top: rem(37);
      }

      .inputs {
        display: grid;
        gap: rem(15) rem(68);
        &.two-columns {
          grid-template-columns: 1fr 1fr;
        }

        &.checkboxes,
        &.radios {
          row-gap: rem(26);
          .checkbox-label,
          .radio-label {
            max-width: rem(720);
          }
        }

        &.big-radios {
          display: flex;
          flex-direction: row;
          gap: 0;
          .big-radio {
            margin-right: rem(12);
            &:last-child {
              margin-right: rem(0);
            }
          }
          &:not(:last-child) {
            margin-bottom: rem(8);
          }
        }

        &.inputs-hidden {
          display: none;
        }
      }
      .input-wrapper {
        display: flex;
        flex-direction: column;
        &.with-margin {
          margin-bottom: rem(20);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .bold-label {
        font-weight: 700;
      }

      &.files-section {
        margin-bottom: rem(32);
      }

      .hidden {
        display: none;
      }
    }
  }
}
