@import '../../styles/main';

.react-select {
  :global {
    .react-select__control {
      border-radius: 0;
      border: none;
      box-shadow: none;
      min-height: 0;

      &.react-select__control--is-focused {
        z-index: 2;
      }

      &.react-select__control--menu-is-open {
      }

      .react-select__value-container {
        cursor: pointer;
        padding-left: 0;
        padding-right: 0;
        min-width: rem(20);

        .react-select__placeholder {
          margin-left: 0;
          margin-right: 0;
          font-family: $fontFamily;
          font-size: rem(14);
          line-height: rem(16);
          color: $aluminium;
        }
        .react-select__single-value {
          font-family: $fontFamily;
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(16);
          color: $royalBlue;

          position: static;
          max-width: none;
          transform: none;

          margin-left: rem(2);
          margin-right: rem(2);
        }
        :last-child {
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
        .react-select__input {
          input {
            font-family: $fontFamily !important;
            font-size: rem(14) !important;
            line-height: rem(16) !important;
            color: $royalBlue !important;
          }
        }

        &.react-select__value-container--is-multi {
          max-width: rem(233);
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;

          &.react-select__value-container--has-value {
            padding: rem(11);
            padding-top: rem(10);
            .react-select__multi-value {
              cursor: default;
              box-shadow: 0 rem(2) rem(6) rgba($paua, 0.1);
              background-color: $white;
              border-radius: rem(3);

              position: relative;

              display: flex;
              flex-direction: row;
              align-items: center;
              min-height: rem(32);

              overflow: hidden;

              margin: 0 rem(4);
              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }

              &::before {
                content: '';
                display: inline-block;
                align-self: stretch;

                width: rem(4);
                background-color: $royalBlue;
              }

              .react-select__multi-value__label {
                margin: 0 rem(7) 0 rem(8);
                padding: 0;
                padding-left: 0;

                font-family: $fontFamily;
                font-size: rem(14);
                line-height: rem(16);
                color: $codGray;
              }
              .react-select__multi-value__remove {
                align-self: stretch;
                background-color: $selago;
                padding: rem(4);

                transition: background-color 0.3s;

                cursor: pointer;

                &::before {
                  content: '';
                  display: inline-block;
                  height: rem(2);
                  width: rem(5);
                  border-radius: rem(1);
                  background-color: $stormGray;
                }

                &:hover {
                  background-color: rgba($carnation, 0.6);
                }

                & > svg {
                  display: none;
                }
              }
            }
          }
        }
      }
      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          padding: 0;
          cursor: pointer;
          & > svg {
            fill: $stormGray;
          }
          &.react-select__clear-indicator {
            & > svg {
              width: rem(14);
            }
          }
          &.react-select__dropdown-indicator {
            & > svg {
              width: rem(14);
            }
          }
        }
        .react-select__clear-indicator ~ .react-select__dropdown-indicator {
          display: none;
        }
      }
    }
    .react-select__menu {
      box-shadow: none;
      border-radius: 0;

      padding-top: 0;
      margin-top: rem(-3);
      margin-bottom: 0;

      .react-select__menu-list {
        padding-top: 0;
        padding-bottom: 0;
        .react-select__option {
          font-family: $fontFamily;
          font-size: rem(14);
          line-height: rem(16);
          color: $codGray;

          padding: rem(4) 0 rem(2) rem(2);

          transition: color 0.3s;

          cursor: pointer;

          &.react-select__option--is-selected {
            color: $aluminium;
            background-color: inherit;
          }
          &.react-select__option--is-focused {
            color: $royalBlue;
            background-color: inherit;
          }
        }
      }
    }
  }
}
