@import '../../../styles/main';

@mixin quiz-item {
  padding-top: rem(30);
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: rem(20);
    .control-panel {
      button {
        padding-right: rem(6);
      }
    }
  }

  .title {
    font-size: rem(20);
    font-weight: 500;
  }
  .subtitle {
    font-size: rem(14);
    padding-top: rem(7);
    color: $aluminium;
  }
  .subtitle-active {
    font-size: rem(14);
    padding-top: rem(7);
    color: $royalBlue;
  }
}
