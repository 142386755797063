@import '../../../styles/main';

@mixin editable-defence {
  p {
    font-size: rem(14);
    padding-left: rem(50);
    padding-right: rem(50);
  }

  .defence-button {
    font-weight: 400;
    width: rem(146);
    font-size: rem(14);
    color: rgba(107, 115, 138, 0.7);
    background-color: transparent;
    height: rem(46);
  }

  .clear-button {
    @extend .defence-button;
  }

  .cancel-button {
    @extend .defence-button;
    margin-left: auto;
  }

  .save-button {
    @extend .defence-button;
    background-color: $royalBlue;
    color: $white;
    font-weight: 500;
    font-size: rem(15);
    box-shadow: 0px 4px 20px rgba(78, 104, 238, 0.3);
    border-radius: 6px;

    &.disabled {
      background-color: rgba(107, 115, 138, 0.7);
      pointer-events: none;
      box-shadow: 0px 4px 6px rgba(107, 115, 138, 0.7);
    }
  }

  .drag-able-text {
    text-decoration: underline;
    text-decoration-color: $royalBlue;
  }

  .control-panel {
    padding-top: rem(30);
    padding-right: rem(50);
    display: flex;
  }
}
