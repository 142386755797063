@import '../../../../styles/main';

@mixin notes {
  padding: rem(42) rem(41);

  background-color: $white;
  box-shadow: 0 rem(1) rem(28) rgba($prussianBlue, 0.05);
  border-radius: rem(13);

  .notes-title {
    font-weight: 500;
    font-size: rem(20);
    line-height: rem(23);
    margin-bottom: rem(11);
  }

  .notes-list {
    max-height: rem(120);
    margin-bottom: rem(32);
    overflow-y: auto;
    @include no-scrollbar;

    .note-item {
      margin-bottom: rem(8);
      &:last-child {
        margin-bottom: 0;
      }
      .note {
        color: $codGray;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: $stormGray;
        }
      }
    }

    .no-notes {
      color: $aluminium;
    }
  }

  .note-input-container {
    width: 100%;
    .note-input {
      padding-top: rem(13);
      padding-bottom: rem(12);
      border-radius: rem(8);
      box-shadow: none;
      .add-note-button {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        font-weight: 500;
        color: $royalBlue;

        transition: color 0.3s;

        &:hover {
          color: $cornBlue;
          .plus-icon {
            background-color: $cornBlue;
          }
        }

        &:active {
          color: $persianBlue;
          .plus-icon {
            background-color: $persianBlue;
          }
        }

        .plus-icon {
          width: rem(15);
          height: rem(15);
          border-radius: rem(7.5);
          background-color: $royalBlue;

          transition: background-color 0.3s;

          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          margin-right: rem(5);

          &::before,
          &::after {
            position: absolute;
            content: '';
            border-radius: rem(1);
            background-color: $white;
          }

          &::before {
            width: rem(5);
            height: rem(1);
          }
          &::after {
            width: rem(1);
            height: rem(5);
          }
        }
      }
    }
  }
  .add-notes-button {
    width: 100%;
    min-height: rem(43);
    border-radius: rem(8);
    background-color: $selago;
    font-size: rem(14);
    line-height: rem(16);
    color: $stormGray;
  }
}
