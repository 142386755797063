%id {
  width: rem(46);
}
%name {
  width: rem(164);
}
%email {
  width: rem(230);
}
%phone-number {
  width: rem(130);
}
%building {
  width: rem(222);
}
%violation {
  width: rem(104);
}
%amount {
  width: rem(96);
}
%status {
  width: rem(60);
}
