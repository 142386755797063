.input-container {
  display: inline-flex;
  flex-direction: column;
}

input,
.input,
textarea,
.textarea {
  font-family: $fontFamily;
  font-size: rem(14);
  line-height: rem(16);
  color: $codGray;

  caret-color: $codGray;

  background-color: transparent;

  cursor: text;

  &::placeholder {
    color: $aluminium;
  }

  &:disabled {
    cursor: not-allowed;
    &::placeholder {
      color: rgba($aluminium, 0.6);
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color: $white !important;
    transition: background-color 5000s ease-in-out 0s, border-color 0.3s,
      box-shadow 0.3s;
    &:disabled {
      background-color: $selago !important;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  resize: none;
}

label,
.input-label {
  font-family: $fontFamily;
  font-weight: 500;
  font-size: rem(12);
  line-height: rem(14);
  color: $codGray;

  &[for] {
    cursor: pointer;
  }
}

.input-label {
  margin-bottom: rem(15);
}
