@import '../../../styles/main';

@mixin top-section {
  grid-area: top-section;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.defaulted,
  &.ecb-hearing {
    flex-direction: row;
    padding: rem(38) rem(63);
    background-color: rgba($jaffa, 0.13);
    border-radius: 0 0 rem(60) rem(60);

    .text {
      max-width: rem(218);
      .title {
        font-weight: 500;
        font-size: rem(20);
        line-height: rem(23);
        margin-bottom: rem(13);
      }
      .subtitle {
        line-height: rem(24);
      }
    }
    .progress-container {
      display: inline-flex;
      width: css-min(rem(192), 29%);
      flex-direction: column;
      margin: 0 rem(8);
      .progress-text {
        width: 100%;
        margin-bottom: rem(7);
      }
      .progress {
        width: 100%;
        height: rem(3);

        &::-webkit-progress-bar {
          background-color: rgba($codGray, 0.2);
        }
        &::-moz-progress-bar {
          background-color: rgba($codGray, 0.2);
        }
        &::-webkit-progress-value {
          background-color: $royalBlue;
        }
      }
    }
    @include media-xs {
      flex-direction: column;
      align-items: stretch;
      .progress-container {
        width: 100%;
        margin: rem(16) 0;
      }
    }
  }

  &.defended {
    flex-direction: row;
    justify-content: flex-end;
    padding-right: rem(41);
    padding-left: rem(41);
    padding-bottom: 0;
  }
}

.reopen-button {
  margin-left: auto;
}
