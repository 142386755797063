@import '../../../../styles/main';

@mixin question-item-edit {
  display: flex;
  margin-top: rem(20);
  background-color: $selago;
  border-radius: rem(16);
  padding: rem(20) rem(20) rem(20) rem(50);

  .question-item-block {
    width: 100%;
    .question-item-number {
      padding-right: rem(20);
      padding-top: rem(15);
      font-weight: 700;
      color: $aluminium;
      font-size: rem(23);
    }
    .header {
      margin-top: rem(4);
      display: flex;
      flex-direction: row;
      input {
        width: 100%;
        color: $codGray;
        font-weight: 500;
        font-size: rem(20);
      }
    }
    .answers {
      padding-left: rem(30);
      padding-top: rem(10);
      padding-right: rem(30);

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .answer-item {
        margin-top: rem(7);
        border-radius: rem(6);
        display: flex;
        flex-direction: row;
        height: rem(40);
        background-color: $selagoDark;
        align-items: center;
        min-width: 150px;
        justify-content: center;
        input {
          text-align: center;
          margin-right: rem(4);
          color: $stormGray;
        }
      }
    }
  }
}
